import React from 'react';
//Importaciones de Menu
import { Navbar, Nav, Container, Button, Row, Col, } from 'react-bootstrap';
import logo from '../images/logo.png'
import logo2 from '../images/logo2.jpeg'
import { Outlet, Link } from "react-router-dom"
import Flip from 'react-reveal/Flip';

const Privacy = () => {
  return (
   

       <div>
        <section className='Menu pb-5'>
    <div>
              <Container>
              <Navbar collapseOnSelect expand="lg" className="bg-transparent respm" style={{ marginTop: '20px' }}>     
              <Navbar.Brand as={Link} to="/" >
                <Flip left><img alt="logo" src={logo} width="20%" height="auto" /></Flip>
                <Flip left><img alt="logo" src={logo2} width="20%" height="auto" className=" m-2"/></Flip>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
              </Nav>
              {/* Botón en la parte derecha */}
              <Flip right><Button variant="danger" style={{ marginLeft: '10px',  borderRadius: '20px' }} className="d-sm-none d-md-block">
               Contactanos
               </Button></Flip>              
              </Navbar.Collapse>    
               </Navbar>
              <section>
              <Outlet>
              </Outlet>
              </section> 
              </Container>
              </div>
</section>




<section>
    <Container>
    <div>
        <h1 className='pb-3 fw-bold '> Aviso de Privacidad </h1>
        <p>Reactivaccion y desarrollo económico de la Calle Azcapotzalco la villa 304,colonia santa catalina municipio Azcapotzalco CP, 02250 CDMX, a través de la Dirección de Promoción Económica, es la responsable del tratamiento de los datos personales que nos proporcione, los cuales serán protegidos en el Sistema de Datos Personales “DE LAS PERSONAS QUE RECIBEN ASISTENCIA TÉCNICA POR PARTE DEL PROGRAMA REACTIVACION Y DESARROLLO ECONOMICO   ”.    </p>
    </div>

    <div className='p-2'>
        <p>Los datos personales que recabemos serán utilizados con el propósito de verificar que las personas que reciban Asistencia Técnica cumplan con los requisitos establecidos en las reglas de operación del “Programa de Financiamiento del programa REACTIVACION Y DESARROLLO ECONOMICO” y de esta manera realizar el registro de los beneficiarios de los servicios no financieros con los que cuenta el fideicomiso, con la finalidad de generar reportes y en su caso, establecer comunicación con ellos. Y serán transferidos a la Comisión de Derechos Humanos de la Ciudad de México para la investigación de quejas y denuncias por presuntas violaciones a los Derechos Humanos; al Instituto de Transparencia, Acceso a la Información Pública, Protección de Datos Personales y Rendición de Cuentas de la Ciudad de México para la sustanciación de Recursos de revisión y denuncias, así como lo relacionado con el procedimiento para determinar el presunto incumplimiento de la Ley de Protección de Datos Personales en Posesión de Sujetos Obligados de la Ciudad de México; a la Auditoria Superior de la Ciudad de México (ASCM) para el ejercicio de sus funciones de fiscalización; a los Órganos de Control para la realización de auditorías o realización de investigaciones por presuntas faltas administrativas; y Órganos Jurisdiccionales Locales y Federales para la substanciación de los procedimientos jurisdiccionales tramitados ante ellos. Usted podrá manifestar la negativa al tratamiento de sus datos personales directamente ante la Unidad de Transparencia del Fondo para el Desarrollo Social de la Ciudad de México, ubicada en Calle Azcapotzalco la villa 304,colonia santa catalina municipio Azcapotzalco CP, 02250 CDMX.</p>
    </div>
    </Container>
</section>







<section>
<footer className='footer mt-5'>
      <Container>
        <Row>
          <Col md={4}>
            <p>Nuestra misión es incentivar el crecimiento económico, nacional, regional y sectorial, mediante el fomento a la productividad e innovación de las micro, pequeñas y medianas empresas ubicadas en sectores estratégicas, que impulse el fortalecimiento ordenado, planificado y sistemático del emprendimiento y del desarrollo empresarial en todo el territorio nacional, así como la consolidación de una economía innovadora, dinámica y competitiva</p>
          </Col>
          <Col md={4}>
            <h4 className="fw-bold">Contacto</h4>
            <ul class="list-unstyled m-0">
              <li className="mb-2 fw-bold">Direccion</li>
              <li className="mb-2">Oficinas centrales CDMX</li>
              <li className="mb-2 fw-bold">Correo</li>
              <li className="mb-2">contacto@emprendimientoconimpulso.org.mx</li>
              <li className="mb-2 fw-bold">Telefono</li>
              <li>5512445970</li>
            </ul>
          </Col>
          <Col md={4}>
            <h4 className="fw-bold">Nosotros</h4>
            <p>Nuestro Consejo Directivo está conformado por emprendedoras y emprendedores que, de forma honoraria, dedican su tiempo a las actividades más estratégicas de la asociación. Se renueva cada tres años por votación de la Asamblea General. La Presidencia del Consejo Directivo es elegida de entre los integrantes del mismo Consejo Directivo.</p>
          </Col>
        </Row>
        <div className='text-center text-white p-3 ' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        Copyright © 2023 Desarrollo al Comercio. Todos los derechos reservados.
       
      </div>
      </Container>
    </footer>
</section>
       </div>

  );
};

export default Privacy;
