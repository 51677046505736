//Importaciones de Menu
import { Outlet, Link } from "react-router-dom"
import logo from '../images/logo.png'
import logo2 from '../images/logo2.jpeg'
//imagenes de carousel
import caru1 from '../images/car1.jpg'
import caru2 from '../images/car2.jpg'
import caru3 from '../images/car3.jpg'
// iMPORTACIONES DE TODO Boostrap
import { Navbar,Card, Nav, Container, Button, Carousel, Row, Col, ListGroup, Form } from 'react-bootstrap';
//Importaciones de section 1
import secta from '../images/secta.jpg';
import sectb from '../images/sectb.jpg';
import sectc from '../images/sectc.jpg';
import sectd from '../images/sectd.jpg';
import secte from '../images/secte.jpg';
import sectf from '../images/sectaf.jpg';
//IMPORTACIONES DE SECTION2
import '../css/Home.css'; 
import emailjs from '@emailjs/browser';
//imagen de advertncia 
import Adv from '../images/adv.png'
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
// WHATSAPP BOTON
import WhatsAppFloatingButton from '../components/whatsapp';
//GOOGLE ANALYTICS
// IMPORTACIONES DE SECTION3
import bandea from '../images/bande1.png';
import bandeb from '../images/bande2.png';
import bandec from '../images/bande3.png';
import banded from '../images/bande4.png';
// IMORTACIONES SECTION 2
import carta from '../images/cart1.png';
import cartb from '../images/cart2.png';
import cartc from '../images/cart3.png';
import React, { useRef} from 'react';



function Home() {

  //PARA ENVIAR AL CORREO///////
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_p2ot0g3', 'template_n6f00at', e.target, 'IZ2SNlDNHCCwYggMN')
      .then((result) => {
          console.log(result.text);
          window.location.reload();
      }, 
      (error) => {
          console.log(error.text);
      }
      );
  };
//////////////////////////////
// Define opciones de estilo que pueden ser editadas/////////////////
  const styles = {
    title: {
      fontSize: '30px', // Tamaño del título
      color: '#10312B',   // Color del título
      margin: '20px 0', // Margen superior e inferior
      fontWeight: 'bold', // Peso de la fuente
    },
    subtitle: {
      fontSize: '30px', // Tamaño del subtítulo
      color: '#10312B',    // Color del subtítulo
      margin: '20px 0', // Margen superior e inferior
      fontWeight: 'bold', // Peso de la fuente
    },
  };
  const numeroTelefono = 'tel:+5512445970'; // Reemplaza con el número de teléfono deseado

////////////////////////////////////////////////////////////////////
return ( 

<div className="Homee">
<div>
      {/* Otros componentes de tu aplicación */}
      <WhatsAppFloatingButton  id="boton2whatsts" className="pulsating-button"/>
    </div>
  <section className='Menu'>
    <div>
              <Container>
              <Navbar collapseOnSelect expand="lg" className="bg-transparent respm" style={{ marginTop: '20px' }}>     
              <Navbar.Brand as={Link} to="/" >
                <Flip left><img alt="logo" src={logo} width="20%" height="auto" /></Flip>
                <Flip left><img alt="logo" src={logo2} width="20%" height="auto" className=" m-2"/></Flip>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
              </Nav>
              {/* Botón en la parte derecha */}
              <Flip right><Button variant="danger" id="botonllamadas"  href={numeroTelefono} style={{ marginLeft: '10px',  borderRadius: '20px' }} className="d-sm-none d-md-block">
               Contactanos
               </Button></Flip>              
              </Navbar.Collapse>    
               </Navbar>
              <section>
              <Outlet>
              </Outlet>
              </section> 
              </Container>
              </div>
</section>
{/* /////////////////////////////////////////////////////////////////////// */}
<section className="Carousel">
  <div>

    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={caru1}
          alt="Primer slide"
        />
        <div className="carousel-caption d-flex h-100 align-items-center justify-content-center text-center">
          <div>
          <h3>Conseguir Apoyos para Comercio Local y Nacional.</h3>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={caru2}
          alt="Segundo slide"
        />
        <div className="carousel-caption d-flex h-100 align-items-center justify-content-center text-center">
          <div>
          <h3>Dias de Financiamiento Empresarial en Mexico.</h3>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={caru3}
          alt="Tercer slide"
        />
        <div className="carousel-caption d-flex h-100 align-items-center justify-content-center text-center">
          <div>
        <h3>Desarrollo de Competencias y Habilidades de Emprendimiento e Inovacion Empresarial.</h3>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
   
    </div>
</section>

{/* /////////////////////////////////////////////////////////////////////// */}

<section className='section1'>
<div>
<Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
        <Fade left><h1 style={styles.title}>Activación y desarrollo económico</h1></Fade>
        <Fade right><h2 style={styles.subtitle}>Inicia o haz crecer tu negocio o empresa</h2></Fade>
        <Fade top><h2 style={styles.subtitle}>Por medio de Financiamiento</h2></Fade>
        </Col>
      </Row>

      <Row>
    <div className='Titlesec'>
        <Col>
        <Fade><h1 className='TitleP'>Acerca de...</h1></Fade>
        <Fade><h2 className='SubtiP'>FOMENTO AL EMPRENDIMIENTO</h2></Fade>
        </Col>
    </div>
      </Row>
      <Row className="mt-4">
        {/* Tarjetas arriba */}
        <Col md={4}>
          <Card className="h-100 shadow-sm hover-effect no-border">
            <Card.Img variant="top" src={secta} />
            <Card.Body>
              <Card.Title>CREDITO MUJER</Card.Title>
              <Card.Text>Este programa busca el desarrollo y consolidación de micro, pequeñas y medianas empresas lideradas por mujeres, con acceso a financiamiento preferencial y a herramientas de desarrollo empresarial.</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="h-100 shadow-sm hover-effect no-border">
            <Card.Img variant="top" src={sectb} />
            <Card.Body>
              <Card.Title>EMPRENDEDOR: </Card.Title>
              <Card.Text>Son espacios  que dan asesoría integral a emprendedores, sin importar si están en la etapa de planeación de su negocio o ya está en marcha. Además, imparten diferentes programas dirigidos a empresarios.</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="h-100 shadow-sm hover-effect no-border">
            <Card.Img variant="top" src={sectc}/>
            <Card.Body>
            <Card.Title>CRÉDITO PYME</Card.Title>
              <Card.Text>Es un producto financiero que permite solventar las necesidades financieras de empresas y proyectos. Contamos con varias opciones que se adaptan a ti.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        {/* Tarjetas abajo */}
        <Col md={4}>
          <Card className="h-100 shadow-sm hover-effect no-border">
            <Card.Img variant="top" src={sectd} />
            <Card.Body>
              <Card.Title>Sectores Productivos</Card.Title>
              <ListGroup>
              <ListGroup.Item>Industria</ListGroup.Item>
              <ListGroup.Item>Comercio</ListGroup.Item>
              <ListGroup.Item>Servicios</ListGroup.Item>
              <ListGroup.Item>Turismo</ListGroup.Item>
              <ListGroup.Item>Tecnología</ListGroup.Item>
            </ListGroup>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="h-100 shadow-sm hover-effect no-border">
            <Card.Img variant="top" src={secte} />
            <Card.Body>
              <Card.Title>Participan</Card.Title>
              <Card.Text>Micro, pequeñas y medianas empresas (MIPYMES).</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="h-100 shadow-sm hover-effect no-border">
            <Card.Img variant="top" src={sectf} />
            <Card.Body>
              <Card.Title>Dirigido a</Card.Title>
              <ListGroup>
              <ListGroup.Item>Persona moral</ListGroup.Item>
      <ListGroup.Item>Persona física con actividad empresarial</ListGroup.Item>
      <ListGroup.Item>Régimen de Incorporación Fiscal (RIF)</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
  
    </Container>
    </div>
</section>

{/* /////////////////////////////////////////////////////////////////////// */}

<section className='section2 mt-5'>
<div className="background-section2">
<Container className="mt-5">
      <Row className="justify-content-center pb-5 pt-4">
        <Col md={8} className="text-center">
        <Fade left><h1 style={styles.title}>FOMENTO AL EMPRENDIMIENTO 2024 - 2025</h1></Fade>
        <Fade><p className="ParfP mb-3">
        Tenemos como misión impulsar a que los y las microempresarias emprendan, desarrollen y consoliden sus negocios, mediante el otorgamiento de servicios de microfinanzas integrales para incrementar la productividad de sus negocios y mejorar sus condiciones de vida.
        </p></Fade>
        <Fade>
        <p className="ParfP">
        Busca apoyar e impulsar al emprendedor en mejorar, ampliar e incentivar la cultura empresarial en México. Cada vez más personas se proponen ser emprendedores y lanzan emprendimientos en diferentes ámbitos, pero necesitan de ayuda.
        </p></Fade>
        </Col>
      </Row>

      <Row className="justify-content-center pb-5 pt-4">
        {/* Imagen 1 */}
        <Col xs={12} md={6} lg={3} className="mb-4">
        <Bounce top><img src={carta} alt="Imagen 1" className="img-fluid" /></Bounce>
        </Col>

        {/* Imagen 2 */}
        <Col xs={12} md={6} lg={3} className="mb-4">
        <Bounce bottom><img src={cartb} alt="Imagen 2" className="img-fluid" /></Bounce>
        </Col>

        {/* Imagen 3 */}
        <Col xs={12} md={6} lg={3} className="mb-4">
        <Bounce top><img src={cartc} alt="Imagen 3" className="img-fluid" /></Bounce>
        </Col>
      </Row>
    </Container>
</div>
</section>

{/* /////////////////////////////////////////////////////////////////////// */}

<section>
    <div className='section3'>
    <Container className="mt-5">
    <Row className="justify-content-center">
        <Col>
        <Fade left><h2 className='tituloEstilo'>CARACTERÍSTICAS DEL CRÉDITO</h2></Fade>
          <Fade><ListGroup variant="flush ParfP">
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>1. MONTO: hasta 5 millones de pesos por (negocio, empresa o proyecto).</ListGroup.Item>
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>2. TASA: 7% hasta 16% tasa fija.</ListGroup.Item>
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>3. PLAZO: Hasta 180 meses (incluyendo periodo de gracia*).</ListGroup.Item>
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>*Gracia. Hasta 3 meses: 3 meses en capital e intereses.</ListGroup.Item>
          </ListGroup></Fade>
          <Fade left><h2 className='tituloEstilo mp-5'style={{ marginTop: '20px', marginBottom: '60px'}}>¿QUÉ NECESITAS?</h2></Fade>
        </Col>
      </Row>

      <Row>
        <Col sm={6} className="centrarcolsec3">
        <Bounce top><img
            src={bandea}
            alt="Imagen 1"
            className="img-fluid"
            style={{ marginBottom: '10px' }}
          /></Bounce>
        </Col>
        <Col sm={6} className="centrarcolsec3">
        <Bounce bottom><img
            src={bandeb}
            alt="Imagen 2"
            className="img-fluid"
            style={{ marginBottom: '10px' }}
          /></Bounce>
        </Col>
        <Col sm={6} className="centrarcolsec3">
        <Bounce top><img
            src={bandec}
            alt="Imagen 3"
            className="img-fluid"
            style={{ marginTop: '10px' }}
          /></Bounce>
        </Col>
        <Col sm={6} className="centrarcolsec3">
        <Bounce top><img
            src={banded}
            alt="Imagen 3"
            className="img-fluid"
            style={{ marginTop: '10px' }}
          /></Bounce>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col>
        <Fade left><h2 className='tituloEstilo'style={{ marginTop: '20px'}}>CARACTERÍSTICAS DEL CRÉDITO</h2></Fade>
        <Fade><ListGroup variant="flush ParfP">
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>Paso 1. Acércate a un Ejecutivo que te asesorará para presentar tu Plan de Negocios.</ListGroup.Item>
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>Paso 2. El emitirá opinión de viabilidad de Proyecto.</ListGroup.Item>
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>Paso 3. Presenta tu Plan de Negocios.</ListGroup.Item>
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>Paso 4. Se evaluará técnica y financieramente tu Proyecto.</ListGroup.Item>
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>Paso 5. Integra y entrega la información legal y financiera que te soliciten.</ListGroup.Item>
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>Paso 6. Una vez autorizado el financiamiento, recíbelo e inicia tu proyecto.</ListGroup.Item>
          </ListGroup></Fade>
        </Col>
      </Row>
    </Container>
    </div>
</section>

{/* /////////////////////////////////////////////////////////////////////// */}

<section>
  <div className='section4'>
  <Container className="d-flex align-items-center justify-content-center min-vh-100 mt-5">

  <Form ref={form} onSubmit={sendEmail}  className="form-inline formulario">

      <Row className="mb-3">
        <Form.Group as={Col} controlId="nombre">
          <Form.Label>Nombre</Form.Label>
          <Form.Control type="text" name="nombre" placeholder="Nombre" />
        </Form.Group>

        <Form.Group as={Col} controlId="apellido">
          <Form.Label>Apellido</Form.Label>
          <Form.Control type="text" name="apellido"  placeholder="Apellido" />
        </Form.Group>
      </Row>

      <Row className="mb-3">
      <Form.Group as={Col} className="mb-3" controlId="email">
        <Form.Label>Correo Electrónico</Form.Label>
        <Form.Control type="email" name="email" placeholder="Ingrese su correo electrónico" required />
      </Form.Group>

      <Form.Group as={Col} className="mb-3" controlId="telefono">
          <Form.Label>Teléfono</Form.Label>
          <Form.Control type="tel" name="telefono" placeholder="Ingrese su número de teléfono" required />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" controlId="montoCredito">
              <Form.Label>Monto de Crédito</Form.Label>
              <Form.Control as="select" name="montoCredito" required>
                <option value="">Seleccione el monto de crédito</option>
                <option value="50,000">$50,000</option>
                <option value="100,000">$100,000</option>
                <option value="200,000">$200,000</option>
                <option value="300,000">$300,000</option>
                <option value="500,000">$500,000</option>
                <option value="1,000,000">$1,000,000</option>
                <option value="3,000,000">$3,000,000</option>
                <option value="5,000,000">$5,000,000</option>
              </Form.Control>
      </Form.Group>

            <Form.Group className="mb-3" controlId="tipoFinanciamiento">
              <Form.Label>Tipo de Financiamiento</Form.Label>
              <Form.Control as="select" name="tipoFinanciamiento" required>
                <option value="">Seleccione el Tipo de Financiamiento</option>
                <option value="Persona Fisica">Persona Física</option>
                <option value="Persona Moral">Persona Moral</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="comentarios">
              <Form.Label>Comentarios</Form.Label>
              <Form.Control as="textarea"  rows={3} name="comentarios" placeholder="Ingrese sus comentarios" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="aceptarTerminos">
              <Form.Check
                type="checkbox"
                name="aceptarTerminos"
                label="Aceptar términos y condiciones"
                required
              />
            </Form.Group>

            <Button  variant="primary" id="BOTONFORMULARIO" type="submit">Enviar Formulario</Button>

    </Form>
    </Container>
  </div>

  <section>
    <div className='section5'>
    <Container fluid className="d-flex align-items-center justify-content-center mt-5">
      <Row>
      <Col md={10} lg={8} xl={6} className="mx-auto text-center seccion">
      <div className="contenido">
          <img
            src={Adv}
            alt="Advertencia"
            className="img-fluid advertencia"
          />
          <Fade bottom big><p>
          Los programas de financiamiento incluidos están sujetos a disponibilidad presupuestal y condicionados al cumplimiento de los requisitos establecidos por la Organización de Capital al Emprendimiento que lo opera. La Unidad de Desarrollo Productivo no participa en el análisis de las solicitudes de crédito.
          NO SON APOYOS, SE ADQUIERE UN COMPROMISO DE PAGO CON LA INSTITUCIÓN CANALIZADA, por lo que se tiene que garantizar.
          </p></Fade>
          </div>

        </Col>
      </Row>
    </Container>
    </div>
  </section>
</section>


<footer className='footer mt-5'>
      <Container>
        <Row>
          <Col md={4}>
            <p>Nuestra misión es incentivar el crecimiento económico, nacional, regional y sectorial, mediante el fomento a la productividad e innovación de las micro, pequeñas y medianas empresas ubicadas en sectores estratégicas, que impulse el fortalecimiento ordenado, planificado y sistemático del emprendimiento y del desarrollo empresarial en todo el territorio nacional, así como la consolidación de una economía innovadora, dinámica y competitiva</p>
          </Col>
          <Col md={4}>
            <h4 className="fw-bold">Contacto</h4>
            <ul class="list-unstyled m-0">
              <li className="mb-2 fw-bold">Direccion</li>
              <li className="mb-2">Oficinas centrales CDMX</li>
              <li className="mb-2 fw-bold">Correo</li>
              <li className="mb-2">contacto@emprendimientoconimpulso.org.mx</li>
              <li className="mb-2 fw-bold">Telefono</li>
              <li>Tel.5511870070</li>
            </ul>
          </Col>
          <Col md={4}>
            <h4 className="fw-bold">Nosotros</h4>
            <p>Nuestro Consejo Directivo está conformado por emprendedoras y emprendedores que, de forma honoraria, dedican su tiempo a las actividades más estratégicas de la asociación. Se renueva cada tres años por votación de la Asamblea General. La Presidencia del Consejo Directivo es elegida de entre los integrantes del mismo Consejo Directivo.</p>
            <Nav.Link as={Link} to="/Aviso" className='navLinkStyle text-center m-3'>Aviso de Privacidad</Nav.Link>
          </Col>
        </Row>
        <div className='text-center text-white p-3 ' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        Copyright © 2023 Desarrollo al Comercio. Todos los derechos reservados.
       
      </div>
      </Container>
    </footer>
</div>



 );
}

export default Home;
