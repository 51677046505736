import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
//importamos los comp creados
import Home from './components/Home';
import Aviso from './components/Aviso';

const App = () => {
return (
    <div className="App">

<BrowserRouter>
<Routes>
  <Route>
    <Route index element={ <Home/> } />
    <Route path='Aviso' element={ <Aviso/> } />
    <Route path='*' element={ <Navigate replace to="/"/> }/>
  </Route>
</Routes> 
</BrowserRouter>

    </div>
  );
}

export default App;